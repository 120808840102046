import React from "react"
import { Box, Flex, Heading } from "rebass/styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

const IndexPage = ({ data }) => {
  const {
    allFile: { edges: images },
  } = data

  const foodImages = images.filter(({ node: image }) =>
    image.absolutePath.includes("/food/")
  )

  const interierImages = images
    .filter(({ node: image }) => image.absolutePath.includes("/interier/"))
    .sort((a, b) => {
      if (a.node.absolutePath.includes("interier-")) {
        return -1
      } else return 1
    })

  return (
    <Layout>
      <SEO title="Galerie" />
      <Box width="100%" sx={{ textAlign: "center" }}>
        <Heading fontSize={7} color="accent" my={2}>
          Galerie
        </Heading>
        <SimpleReactLightbox>
          <SRLWrapper
            options={{
              overlayColor: "rgb(0, 0, 0, .5)",
              enablePanzoom: false,
              hideControlsAfter: 0,
              showThumbnails: false,
              autoplaySpeed: 3000,
            }}
          >
            <Flex
              width="100%"
              flexWrap="wrap"
              justifyContent={["flex-start"]}
              maxWidth={300 * 5}
              mx="auto"
              mb={3}
              onClick={() => {
                setTimeout(() => {
                  document.querySelector(".SRLAutoplayButton").click()
                }, 100)
              }}
            >
              {foodImages.map(({ node: image }) => (
                <Box
                  key={image.id}
                  width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 5]}
                  maxWidth={["auto", "auto", "auto", 300]}
                  px={1}
                  py={0}
                  mt={1}
                  sx={{ cursor: "pointer" }}
                >
                  <img src={image.publicURL} />
                </Box>
              ))}
            </Flex>
          </SRLWrapper>
        </SimpleReactLightbox>

        <Heading fontSize={7} color="accent" my={2}>
          NAŠ INTERIÉR
        </Heading>
        <SimpleReactLightbox>
          <SRLWrapper
            options={{
              overlayColor: "rgb(0, 0, 0, .5)",
              enablePanzoom: false,
              hideControlsAfter: 0,
              showThumbnails: false,
              autoplaySpeed: 3000,
            }}
          >
            <Flex
              width="100%"
              flexWrap="wrap"
              justifyContent={["flex-start"]}
              maxWidth={300 * 5}
              mx="auto"
              mb={3}
              onClick={() => {
                setTimeout(() => {
                  document.querySelector(".SRLAutoplayButton").click()
                }, 100)
              }}
            >
              {interierImages.map(({ node: image }) => (
                <Box
                  key={image.id}
                  width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 5]}
                  maxWidth={["auto", "auto", "auto", 300]}
                  px={1}
                  py={0}
                  mt={1}
                  sx={{ cursor: "pointer" }}
                >
                  <img src={image.publicURL} />
                </Box>
              ))}
            </Flex>
          </SRLWrapper>
        </SimpleReactLightbox>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { absolutePath: { regex: "/gallery-page/" } }) {
      edges {
        node {
          id
          publicURL
          absolutePath
        }
      }
    }
  }
`

export default IndexPage
